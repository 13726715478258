import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import { LEGAL_BANNER_COOKIE, LEGAL_BANNER_COOKIE_VALUE } from '@/constants'
import Cookies from 'js-cookie'

interface ILegalBannerContext {
  isDisclaimerOpen: boolean
  setIsDisclaimerOpen: (isDisclaimerOpen: boolean) => void
}

const LegalBannerContext = createContext<ILegalBannerContext>({
  isDisclaimerOpen: false,
  setIsDisclaimerOpen: () => {},
})

export const useLegalBannerContext = () => useContext(LegalBannerContext)

export const LegalBannerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false)

  useEffect(() => {
    if (Cookies.get(LEGAL_BANNER_COOKIE) !== LEGAL_BANNER_COOKIE_VALUE) {
      setIsDisclaimerOpen(true)
    }
  }, [])

  return (
    <LegalBannerContext.Provider
      value={{ isDisclaimerOpen, setIsDisclaimerOpen }}
    >
      {children}
    </LegalBannerContext.Provider>
  )
}
