import get from 'lodash.get'

import useSiteApiUrl from '@/hooks/useSiteApiUrl'

import { Product } from '../../../lib/@getethos/constants'
import { FeatureFlagConfig } from '../../../lib/@getethos/interfaces'
import { maxCoverageDetermination } from '../../../lib/@getethos/utils/maxCoverageDetermination'
import { maxTermDetermination } from '../../../lib/@getethos/utils/maxTermDetermination'
import { predictProduct } from '../../../lib/@getethos/utils/predictProduct'
import { getQueryString } from '../../../lib/@getethos/utils/utils'
import { DAILY_PREMIUM_DEFAULTS, FINAL_EXPENSE_COVERAGE } from '../../constants'
import { useNavigateToApp } from '../../hooks/useNavigateToApp'
import { useEligibility } from './useEligibility'
import { usePrevious } from './usePrevious'
import { usePriceRange } from './usePriceRange'
import { useRegionFromZipCode } from './useRegionFromZipCode'
import { useSendAnalytics } from './useSendAnalytics'
import { useTermCoverage } from './useTermCoverage'
import { useUserData } from './useUserData'
import {
  coalesce,
  getMedianTerm,
  getMinValidCoverage,
  isValidCoverage,
} from './utils'

/**
 * form state and form submission handling for EstimateWidget
 *
 *
 * @return {Array}
 */
export const useFormState = (
  analytics: any,
  featureFlagConfig?: FeatureFlagConfig
): any => {
  const queryParams = getQueryString()

  const { userData, setUserData, isUserDataComplete, clearUserData } =
    useUserData(analytics, queryParams, featureFlagConfig, useSiteApiUrl())

  const getBirthDate = (): string | undefined => get(userData, 'birthDate')
  const getCreditScore = (): any => get(userData, 'estimatedCredit')

  const experimentCoverage = DAILY_PREMIUM_DEFAULTS.COVERAGE

  const birthDate = coalesce(getBirthDate(), usePrevious(getBirthDate()))
  const creditScore = coalesce(getCreditScore(), usePrevious(getCreditScore()))
  const zipCode = get(userData, 'zipCode')
  const { region } = useRegionFromZipCode(analytics, zipCode)
  const product = predictProduct({ birthDate, creditScore, region })
  const maxCoverage = maxCoverageDetermination(birthDate, product)
  const minCoverage = getMinValidCoverage(product)
  const recommendedCoverage =
    product === Product.Term && featureFlagConfig?.useDailyPremium
      ? experimentCoverage
      : product === Product.FE
      ? FINAL_EXPENSE_COVERAGE.MIN
      : Math.min(350000, maxCoverage)
  const { terms, recommendedTerm } = useEligibility(birthDate)
  const maxTerm = maxTermDetermination(birthDate)

  const median = (maxCoverage + minCoverage) / 2

  const medianAdjusted =
    product === Product.FE ? Math.round(median / 1000) * 1000 : median

  const {
    term,
    coverage,
    debouncedCoverage,
    debouncedTerm,
    changeTermCoverage,
  } = useTermCoverage({
    defaultTerm: featureFlagConfig?.useMedianValues
      ? getMedianTerm(terms)
      : recommendedTerm,
    defaultCoverage: featureFlagConfig?.useMedianValues
      ? medianAdjusted
      : recommendedCoverage,
  })

  // redirect NY Users to Info Page
  if (region === 'NY') {
    window.location.assign(`https://www.ethoslife.com/life/youre-in-new-york/`)
  }

  // Fix term and coverage falling outside possible range after changes to user data
  // If the current term does not appear in the list of possible terms,
  // switch to the possible term closest to the current term
  let updatedTerm = term
  if (terms && !terms.includes(term)) {
    updatedTerm = terms.reduce(function (prev, curr) {
      return Math.abs(curr - term) < Math.abs(prev - term) ? curr : prev
    })
  }
  let updatedDebouncedTerm = debouncedTerm
  if (terms && !terms.includes(debouncedTerm)) {
    updatedDebouncedTerm = terms.reduce(function (prev, curr) {
      return Math.abs(curr - debouncedTerm) < Math.abs(prev - debouncedTerm)
        ? curr
        : prev
    })
  }
  // If the current coverage is larger than the maximum allowed coverage
  // then simply switch it to the maximum allowed coverage
  const updatedCoverage = isValidCoverage(coverage, birthDate, product)
    ? coverage
    : minCoverage

  const updatedDebouncedCoverage = isValidCoverage(
    debouncedCoverage,
    birthDate,
    product
  )
    ? debouncedCoverage
    : minCoverage

  const { ranges, prevRanges, error, clearError } = usePriceRange({
    userData,
    isUserDataComplete,
    term: updatedDebouncedTerm,
    coverage: updatedDebouncedCoverage,
    product,
    region,
    analytics,
    featureFlagConfig,
    creditScore,
  })

  const { handleNavigateToApp } = useNavigateToApp()
  const goToAnalyticsProperties = {
    ...userData,
    product,
    priceElasticityEnabled:
      featureFlagConfig && featureFlagConfig.usePriceElasticity,
    lastMinPrice: ranges.min,
    lastMaxPrice: ranges.max,
    lastTerm: updatedDebouncedTerm,
    lastCoverage: updatedDebouncedCoverage,
  }
  const goToApp = (openNewTab = false): void => {
    analytics.continuedToApp({ properties: goToAnalyticsProperties })
    handleNavigateToApp(
      { ...userData },
      typeof openNewTab === 'boolean' && openNewTab,
      false, // needs route
      featureFlagConfig && featureFlagConfig.useRatesCopy // rates copy in main app
    )
  }

  /**
   * Clears data which will cause the FE to return to the first view
   */
  const editAnswers = (): void => {
    analytics.editAnswers()
    clearUserData()
    clearError()
  }

  const { sendAnalyticsEvent } = useSendAnalytics(analytics)

  return {
    sendAnalyticsEvent,
    handleNavigateToApp: goToApp,
    editAnswers,
    toggleSlider: changeTermCoverage,
    term: updatedTerm,
    terms,
    coverage: updatedCoverage,
    ranges,
    prevRanges,
    error,
    product,
    maxCoverage,
    minCoverage,
    region,
    // updating user data
    userData,
    setUserData,
    isUserDataComplete,
    maxTerm,
  }
}
