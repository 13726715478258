import Cookies from 'js-cookie'
import { FIVE_VS_TEN_MINUTES_HEADER } from 'lib/@getethos/experiments/feature_constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useFiveVsTenHeader = () => {
  const { isEnabled, variation, isLoading } = useDecisionForFlag({
    name: FIVE_VS_TEN_MINUTES_HEADER.name,
    fallbackVariation: FIVE_VS_TEN_MINUTES_HEADER.variations.off,
  })

  if (
    isEnabled &&
    variation === FIVE_VS_TEN_MINUTES_HEADER.variations['10minutes']
  ) {
    Cookies.set(FIVE_VS_TEN_MINUTES_HEADER.cookieName as string, variation, {
      expires: 365,
    })
  } else {
    Cookies.remove(FIVE_VS_TEN_MINUTES_HEADER.cookieName)
  }

  return { variation, isLoading, isEnabled }
}
