import { LeadFormInputFields } from '@/components/EstimateWidget/FloatingVariation/FloatingForm/experiments/LeadForm/utils'

import { ExtraUserData } from '../interfaces'
import { LeadInfo, LeadInfoToJSON } from '../interfaces/LeadInfo'
import {
  PartnerRef,
  PartnerRefToJSON,
  SignupForm,
  SignupFormToJSON,
} from '../interfaces/PartnerData'
import { apiUrl } from '../urls/urls'

const INIT_HEALTH_INDEX = '1'

export interface UserParams {
  gender: string
  birthDate: string
  health: string
  smoker: string | boolean
  zipCode: string
  state: string
  term: string
  coverage: string
  region?: string
  extraUserData?: Partial<ExtraUserData>
  flow?: string
  productType?: string
  utmSource?: string
  [key: string]: any
}

export interface ApiParams {
  gender: string
  birthDate: string
  health: string
  smoker: string
  zipCode: string
  region: string
  term: number
  coverage: number
}

export interface CreatePartnerParams {
  partner: PartnerRef
  form: SignupForm
  lead: LeadInfo
}

interface PrepareApiParams {
  params: ApiParams
  healthClasses: any
}

interface AppPrefillReturn {
  state: string
  gender: string
  smoker: string | boolean
  zipCode: string
  health: string
  birthDate: string
  flow?: string
  productType?: string
  pfk?: string
  p?: string
  lpCopy?: string
  [key: string]: any
}

interface QuoteParamsReturn extends AppPrefillReturn {
  term: string
  coverage: string
  healthClasses: any
}

// when user inputs age directly, we convert it to the MM/DD/YYYY
// format, which allows the backend API to pick up seamlessly.
// however, this does introduce a tradeoff in prefill value during
// pre-interview phase, and thus this is a temp solution for test
// purpose only.
export const convertAgeToBirthDateISO = (age: string): string => {
  const date = new Date()
  date.setFullYear(date.getFullYear() - parseInt(age))
  return date.toISOString()
}

/**
 * Gets the params to make a call to a quote endpoint
 */
export function getQuoteAPIParams({
  params,
  healthClasses,
}: PrepareApiParams): QuoteParamsReturn {
  const { gender, birthDate, smoker, term, zipCode, coverage, region } = params
  const birthDateISO = !Number.isNaN(Number(birthDate))
    ? convertAgeToBirthDateISO(birthDate)
    : new Date(birthDate).toISOString()

  // default to the first health class if nothing is passed
  const health = params.health || INIT_HEALTH_INDEX

  // If select tobacco then show tobacco to standard non tobacco range (just standard)
  if (smoker === 'true') {
    healthClasses = healthClasses[0]
  }

  return {
    term: term.toString(),
    coverage: coverage.toString(),
    state: region,
    smoker,
    gender,
    zipCode,
    health,
    birthDate: birthDateISO,
    healthClasses: JSON.stringify(healthClasses),
  }
}

const mapLeadFormParams = (params: LeadFormInputFields) => {
  const { birthDate, citizen, firstName, gender, lastName, primaryAddress } =
    params

  if (!citizen || !firstName || !lastName || !birthDate) return {}

  return {
    nameFirst: firstName,
    nameLast: lastName,
    gender,
    immigrationStatus: citizen,
    birthDate: new Date(birthDate).toISOString(),
    street: primaryAddress.street1,
    ...(primaryAddress.street2 && { address_2: primaryAddress.street2 }),
    city: primaryAddress.locality,
    zipCode: primaryAddress.postalCode,
    addressRegion: primaryAddress.region,
  }
}

/**
 * Gets the parameters necessary to prefill the main app
 */
export const getAppPrefillParams = (
  params: Partial<UserParams>
): Partial<AppPrefillReturn> => {
  const {
    gender,
    birthDate,
    health,
    smoker,
    zipCode,
    region,
    flow,
    productType,
  } = params

  const leadFormParams = mapLeadFormParams(params as LeadFormInputFields)

  return {
    state: region,
    gender,
    smoker,
    zipCode,
    health,
    birthDate:
      !birthDate || !Number.isNaN(Number(birthDate))
        ? undefined
        : new Date(birthDate).toISOString(),
    flow,
    productType,
    ...leadFormParams,
  }
}

export async function requestCreatePartner(
  params: CreatePartnerParams,
  partnerApiUrl: string
): Promise<any> {
  const request = apiUrl.createPartner(partnerApiUrl)
  const response = await fetch(request, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      partner: PartnerRefToJSON(params.partner),
      form: SignupFormToJSON(params.form),
      lead: LeadInfoToJSON(params.lead),
    }),
  })

  return response
}

export async function requestGetSignupInfo(
  code: string,
  externalGatewayApiUrl: string
): Promise<any> {
  const request = apiUrl.getSignupInfo(externalGatewayApiUrl)
  const response = await fetch(`${request}?code=${code}`, {
    method: 'GET',
  })

  return response.json()
}

export async function encryptData(
  siteApiUrl: string,
  params: Record<string, string>
): Promise<any> {
  const source = params['source'] ?? 'martech'
  if (Object.keys(params).length == 0) {
    return Promise.resolve('')
  }
  const body = JSON.stringify({ ...params, source })
  const request = apiUrl.encryptData(siteApiUrl)
  const response = await fetch(request, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body,
  })
  return response.text()
}

export async function decryptData(
  siteApiUrl: string,
  encryptedString: string,
  source: string
): Promise<any> {
  const request = apiUrl.decryptDataUrl(siteApiUrl)
  const response = await fetch(request, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ encryptedString, source }).replace(/ /g, '+'),
  })

  return response.text()
}
