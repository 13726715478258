const GetSiteUrls = () => {
  let ConfigSiteUrl = ''
  let ConfigSiteDomain = ''
  let ConfigSiteAppUrl = ''
  let ConfigSiteApiUrl = ''
  let ConfigSiteAgentsUrl = ''
  let ConfigSiteAgentsApiUrl = ''
  let ConfigSiteExternalGatewayApiUrl = ''

  const isProductionDeployment =
    process.env.NEXT_PUBLIC_ETHOS_ENV === 'production'

  const URL = process.env.NEXT_PUBLIC_URL || 'https://www.ethoslife.com'

  if (process.env.NEXT_PUBLIC_ETHOS_ENV === 'localhost') {
    ConfigSiteUrl = 'https://www.ethoslife.com'
    ConfigSiteDomain = 'ethoslife.com'
    ConfigSiteAppUrl = 'app.stage.ethoslife.com'
    // ConfigSiteApiUrl = 'api.stage.ethoslife.com'
    ConfigSiteApiUrl = 'localhost:8000'
    ConfigSiteAgentsUrl = 'localhost:8080'
    ConfigSiteAgentsApiUrl = 'localhost:9090'
    ConfigSiteExternalGatewayApiUrl = 'localhost:18082'
  } else {
    // else we get the real production URL from Netlify
    const processEnvUrl = process.env.NEXT_PUBLIC_URL

    if (URL.includes('staging.ethos-int.com')) {
      // `eks.staging.ethos-int.com` points to a kubernetes cluster on the AWS staging account
      // and is only accessible on the internal network via VPN.
      ConfigSiteDomain = 'cms.eks.staging.ethos-int.com'
      ConfigSiteUrl = `https://${ConfigSiteDomain}`
      ConfigSiteAppUrl = 'app.eks.staging.ethos-int.com'
      ConfigSiteApiUrl = 'api.eks.staging.ethos-int.com'
      ConfigSiteAgentsUrl = 'agents.eks.staging.ethos-int.com'
      ConfigSiteAgentsApiUrl = 'partners.eks.staging.ethos-int.com'
      ConfigSiteExternalGatewayApiUrl = 'eg.eks.staging.ethos-int.com'
    }
    // Primary production/stage urls
    else {
      ConfigSiteUrl = URL
      ConfigSiteDomain = ConfigSiteUrl.replace('https://www.', '').replace(
        '.com/',
        '.com'
      )
      if (isProductionDeployment) {
        ConfigSiteAppUrl = `app.${ConfigSiteDomain}`
        ConfigSiteApiUrl = `api.${ConfigSiteDomain}`
        ConfigSiteAgentsUrl = 'agents.ethoslife.com'
        ConfigSiteAgentsApiUrl = 'partners.ethoslife.com'
        ConfigSiteExternalGatewayApiUrl = 'eg.ethoslife.com'
      } else {
        ConfigSiteAppUrl = 'app.stage.ethoslife.com'
        ConfigSiteApiUrl = 'api.stage.ethoslife.com'
        ConfigSiteAgentsUrl = 'agents.stage.ethoslife.com'
        ConfigSiteAgentsApiUrl = 'partners.stage.ethoslife.com'
        ConfigSiteExternalGatewayApiUrl = 'eg.stage.ethoslife.com'
      }
    }
  }
  return {
    ConfigSiteUrl,
    ConfigSiteDomain,
    ConfigSiteAppUrl,
    ConfigSiteApiUrl,
    ConfigSiteAgentsUrl,
    ConfigSiteAgentsApiUrl,
    ConfigSiteExternalGatewayApiUrl,
  }
}

export default GetSiteUrls
