import React from 'react'

import { CloudinaryImage } from 'ethos-design-system'

import Markdown from '@/components/global/Markdown'
import PriorityImage from '@/components/global/PriorityImage'

import { usePaidLandersUpdate } from '@/hooks/features/usePaidLandersUpdate'

import PressWreaths from '../../common/PressWreaths'
import { LayoutProps } from './index'

const PRESS_WREATHS = [
  {
    title: '#1',
    subtitle: 'Instant \n life insurance',
    logoUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1715303162/forbes-light_fctymb.svg',
    logoAlt: 'Forbes',
  },
  {
    title: 'Best',
    subtitle: 'No-exam \n term life policy',
    logoUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1715303162/BI-light_kmxmkg.svg',
    logoAlt: 'Business Insider',
  },
]

const PaidLandersUpdate = ({
  updateScrollNode,
  moduleData,
  formManager,
}: LayoutProps) => {
  const { isTreatment2, isTreatment3 } = usePaidLandersUpdate()

  return (
    <section
      className="flex w-full justify-center overflow-hidden bg-[#056257]"
      ref={updateScrollNode}
    >
      <div className="relative grid w-full max-w-[1160px] items-start justify-items-center px-6 pt-5 lg:grid-cols-2 lg:grid-rows-[repeat(3,auto)] lg:justify-items-start lg:px-12 lg:pt-14">
        <div className="relative mb-10 max-w-[450px] text-center font-['Cambon'] text-[50px] font-bold leading-[1.15] tracking-[-1.375px] text-white lg:max-w-none lg:whitespace-normal lg:text-left lg:text-7xl lg:tracking-normal [&_p]:m-0">
          <div className="relative z-1">
            <Markdown input={moduleData.heading} />
          </div>
          <div className="absolute left-0 top-full z-0 grid h-0 w-full place-content-center overflow-visible lg:hidden">
            <div className="h-[852px] w-[228px] rotate-[-42.73deg] rounded-full bg-[#84CBA0] opacity-40 blur-[100px]" />
          </div>
        </div>
        <div className="relative z-1 mb-12 grid w-full max-w-[384px] grid-cols-2 gap-2 lg:row-start-2 lg:mb-0 lg:max-w-[450px]">
          <div className="col-span-1 grid place-items-center rounded-bl-sm rounded-br-sm rounded-tl-2xl rounded-tr-sm bg-[#E6F5EC] p-2 text-center text-lg">
            <p className="m-0">
              <span className="text-lg font-medium leading-relaxed text-black">
                $2M{' '}
              </span>
              in coverage <br />
              starts at{' '}
              <span className="text-lg font-medium leading-relaxed text-black">
                $2/day
              </span>
            </p>
          </div>
          <div className="col-span-1 grid place-items-center rounded-bl-sm rounded-br-sm rounded-tl-sm rounded-tr-2xl bg-[#E6F5EC] px-2 py-2 text-center text-lg font-normal leading-relaxed">
            <p className="m-0">
              Over
              <span className="text-lg font-medium text-black"> 200,000</span>
              <br />
              families covered
            </p>
          </div>
          <div className="col-span-2 grid place-items-center rounded-bl-2xl rounded-br-2xl rounded-tl-sm rounded-tr-sm bg-[#E6F5EC] py-2.5 font-['Theinhardt'] text-lg">
            No medical exams or blood tests
          </div>
        </div>
        {isTreatment2 && (
          <div className="relative z-1 row-start-4 mb-9 flex w-full max-w-[450px] justify-around lg:row-start-auto lg:my-16">
            {PRESS_WREATHS.map((wreath) => (
              <PressWreaths
                key={wreath.logoUrl}
                title={wreath.title}
                subtitle={wreath.subtitle}
                logoUrl={wreath.logoUrl}
                logoAlt={wreath.logoAlt}
                variant="light"
              />
            ))}
          </div>
        )}
        {isTreatment3 && (
          <div className="relative z-1 row-start-4 flex w-full justify-around self-end lg:row-start-auto lg:mt-9 lg:max-w-[450px]">
            <PriorityImage
              publicId="https://res.cloudinary.com/getethos/image/upload/v1715908509/AdobeStock_389575492_uvhqkk.png"
              fetchpriority="high"
              alt="Father mother and child hugging"
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[220, 220, 250, 250]}
              width={[371, 371, 424, 424]}
            />
          </div>
        )}
        <div className="absolute left-[3rem] top-0 z-0 hidden h-full w-[calc(50%-3rem)] place-items-center overflow-visible lg:grid">
          <div className="absolute z-0 h-[1485px] w-[397px] rotate-[-42.73deg] rounded-full bg-green-300 opacity-40 blur-[200px]" />
        </div>
        <div className="align-center relative z-1 m-auto mb-12 flex justify-center lg:col-start-2 lg:row-span-3 lg:row-start-1 lg:mb-0 lg:mt-3 [&_div>div]:h-auto [&_form>div]:h-auto">
          {formManager}
        </div>
      </div>
    </section>
  )
}

export default PaidLandersUpdate
