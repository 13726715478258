import { useEffect, useState } from 'react'

import { PHASE_PRODUCTION_BUILD } from 'next/dist/shared/lib/constants'

import { useFeature } from '@optimizely/react-sdk'

import { optimizelyInstance } from './optimizelyInstance'

const DEFAULT_VALUES = {
  isEnabled: false,
  variables: {},
  isLoading: false,
}

interface UseFeatureVariableHookReturn {
  isEnabled: boolean
  variables: Record<string, any>
  isLoading: boolean
}

const useOptimizelyFeature = (featureKey: string) => {
  const [isEnabled, variables, clientReady] = useFeature(featureKey)
  const [isLoading, setLoading] = useState(true)

  const instance = optimizelyInstance()

  useEffect(() => {
    if (!instance) return
    instance.onReady().finally(() => setLoading(false))
  }, [clientReady, instance])

  return {
    isEnabled,
    variables,
    isLoading,
  }
}

export const useFeatureVariable = (
  featureKey: string,
  bypassActivate?: boolean
): UseFeatureVariableHookReturn => {
  const getOptimizelyFeature = useOptimizelyFeature

  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    return {
      ...DEFAULT_VALUES,
      isLoading: true,
    }
  }

  if (bypassActivate) {
    return DEFAULT_VALUES
  }

  return getOptimizelyFeature(featureKey)
}
