import qs from 'qs'

/**
 * Checks to see the correct siteAppUrl
 *
 * @param {string} siteAppUrl
 *
 * @return {boolean}
 */
const includesSiteAppUrl = (siteAppUrl: string): boolean => {
  if (
    !siteAppUrl.includes('ethoslife.com') &&
    !siteAppUrl.includes('locoproxy.com')
  ) {
    throw new Error(`${siteAppUrl} app url cannot be used to route`)
  }
  return true
}

/**
 * Checks to see the correct siteApiUrl
 *
 * @param {string} siteApiUrl
 * @param {string} endpoint
 *
 * @return {boolean}
 */
const includesSiteApiUrl = (siteApiUrl: string, endpoint: string): boolean => {
  if (
    !siteApiUrl.includes('ethoslife.com') &&
    !siteApiUrl.includes('locoproxy.com') &&
    !siteApiUrl.includes('localhost:8000')
  ) {
    throw new Error(`${siteApiUrl} Api cannot be used to call ${endpoint}`)
  }
  return true
}

/**
 * For navigation to app.
 * Any internal navigation, use navigate from gatsby
 * https://www.gatsbyjs.org/docs/gatsby-link/#how-to-use-the-navigate-helper-function
 *
 * @param {string} url
 * @param {string} apiUrl
 * @param {Object} [params]
 * @param {boolean} [openNewTab]
 * @param {boolean} [overrideAppUrl]
 */

export const navigateToApp = (
  url: string = '',
  apiUrl: string = '',
  params: Record<string, any> = {},
  openNewTab: boolean = false,
  overrideAppUrl: boolean = false
): void => {
  if (!overrideAppUrl && !includesSiteAppUrl(url)) {
    throw new Error(`Navigating to incorrect app url: ${url}`)
  }

  const redirectToMainApp = (stringifyParams: string): void => {
    let location = url
    if (stringifyParams !== '') {
      location = `${url}?${stringifyParams}`
    }
    // 300 ms delay to allow final analytics events to be released from browser
    setTimeout(() => {
      if (openNewTab) {
        // TS -- https://stackoverflow.com/a/59866345
        window.open(location, '_blank')!.focus()
      } else {
        window.location.assign(location)
      }
    }, 300)
  }

  const redirectParams = { ...params }
  delete redirectParams['utmSource']
  delete redirectParams['utm_source']
  delete redirectParams['martech_data']

  const stringifiedParams = qs.stringify(redirectParams, {
    encodeValuesOnly: true,
  })
  redirectToMainApp(stringifiedParams)
}

export const appRoute = {
  base(siteAppUrl: string): string {
    includesSiteAppUrl(siteAppUrl)

    return `https://${siteAppUrl}`
  },
  term(siteAppUrl: string): string {
    includesSiteAppUrl(siteAppUrl)

    return `https://${siteAppUrl}/term`
  },
  needs(siteAppUrl: string): string {
    includesSiteAppUrl(siteAppUrl)

    return `https://${siteAppUrl}/needs`
  },
  termStartApplication(siteAppUrl: string): string {
    includesSiteAppUrl(siteAppUrl)
    return `https://${siteAppUrl}/term/startapplication`
  },
}

/**
 * apiUrl {Object} is a collection of functions for various API endpoints
 *
 */
export const apiUrl = {
  /**
   * quoteCalculatorPackages
   *
   * @param {string} siteApiUrl dev/stage/prod API URL
   * @param {Object} params for endpoint
   * @param {string} params.state - ex: AL
   * @param {string} params.gender - ex: male
   * @param {bool}   params.smoker - ex: false
   * @param {number} params.health - ex: 2
   * @param {string} params.birthDate - ex ISO: 1993-06-06T07:00:00.000Z
   * @param {string} params.flow - ex: widget
   *
   * @return {Object} recommended packages
   */
  quoteCalculatorPackages(
    siteApiUrl: string,
    params: Record<string, object>
  ): string {
    const protocol = siteApiUrl.includes('local') ? 'http' : 'https'
    const endpoint = '/v3.0/calculator/quote/term/packages'

    includesSiteApiUrl(siteApiUrl, endpoint)

    return `${protocol}://${siteApiUrl}${endpoint}?${qs.stringify(params)}`
  },

  /**
   * quoteCalculatorPriceRange
   *
   * @param {string} siteApiUrl dev/stage/prod API URL
   * @param {Object} params for endpoint
   * @param {string} params.gender - ex: male
   * @param {string} params.birthDate - ex ISO: 1993-06-06T07:00:00.000Z
   * @param {string} params.tobacco - ex: no
   * @param {string} params.region - ex: AL
   * @param {string} params.targetAmount - ex: 200000
   * @param {string} params.term - ex: 30 or absent
   * @param {string} params.creditScore - ex: 760, 710, 600
   * @param {string} params.healthClass - ex: either below or excellent
   *
   * @return {Object} a series of policy prices
   */
  quoteCalculatorGeneral(
    siteApiUrl: string,
    params: Record<string, any>
  ): string {
    const {
      gender,
      birthDate,
      tobacco,
      region,
      targetAmount,
      term,
      creditScore,
      healthClass,
    } = params
    const target = 'coverage'

    const endpoint = '/v3.0/quotes/funnel'
    const protocol = siteApiUrl.includes('local') ? 'http' : 'https'

    includesSiteApiUrl(siteApiUrl, endpoint)

    return `${protocol}://${siteApiUrl}${endpoint}?${qs.stringify({
      gender,
      birthDate,
      tobacco,
      region,
      targetAmount,
      term,
      creditScore,
      healthClass,
      target,
    })}`
  },

  quoteCalculatorWithoutProductDetermination(
    siteApiUrl: string,
    params: Record<string, any>
  ): string {
    const {
      gender,
      birthDate,
      tobacco,
      region,
      targetAmount,
      term,
      creditScore,
      healthClass,
    } = params
    const target = 'coverage'

    const endpoint = '/v3.0/quotes/'
    const protocol = siteApiUrl.includes('local') ? 'http' : 'https'

    includesSiteApiUrl(siteApiUrl, endpoint)

    return `${protocol}://${siteApiUrl}${endpoint}?${qs.stringify({
      gender,
      birthDate,
      tobacco,
      region,
      targetAmount,
      term,
      creditScore,
      healthClass,
      target,
    })}`
  },
  getValidatePhoneUrl(siteApiUrl: string): string {
    const endpoint = '/v3.0/idv/sms/validate'
    const protocol = siteApiUrl.includes('local') ? 'http' : 'https'

    includesSiteApiUrl(siteApiUrl, endpoint)

    return `${protocol}://${siteApiUrl}${endpoint}`
  },

  /**
   * createPartner
   *
   * @param {string} siteAgentsApiUrl dev/stage/prod Partner API root URL
   * @return {string} dev/stage/prod Create partner API URL
   */
  createPartner(siteAgentsApiUrl: string): string {
    const endpoint = '/api/v1/partners'
    const protocol = siteAgentsApiUrl.includes('local') ? 'http' : 'https'

    return `${protocol}://${siteAgentsApiUrl}${endpoint}`
  },

  /**
   * getSignupInfo
   *
   * @param {string} siteExternalGatewayApiUrl dev/stage/prod External Gateway API root URL
   * @returns  {string}  dev/stage/prod Get Signupinfo API URL
   */
  getSignupInfo(siteExternalGatewayApiUrl: string): string {
    const endpoint = '/v1/fetch_signup'
    const protocol = siteExternalGatewayApiUrl.includes('local')
      ? 'http'
      : 'https'

    return `${protocol}://${siteExternalGatewayApiUrl}${endpoint}`
  },

  encryptData(siteApiUrl: string): string {
    const endpoint = '/v3.0/martech/prefill/exchange'
    const protocol = siteApiUrl.includes('local') ? 'http' : 'https'

    includesSiteApiUrl(siteApiUrl, endpoint)
    return `${protocol}://${siteApiUrl}${endpoint}`
  },

  decryptDataUrl(siteApiUrl: string): string {
    const endpoint = '/v3.0/martech/prefill/decrypt'
    const protocol = siteApiUrl.includes('local') ? 'http' : 'https'

    includesSiteApiUrl(siteApiUrl, endpoint)
    return `${protocol}://${siteApiUrl}${endpoint}`
  },
}
