import { FC } from 'react'

import { TitleSmall2 } from 'ethos-design-system'

import styles from './EstimateWidgetLoader.module.scss'

interface IEstimateWidgetLoaderProps {
  isLoading: boolean
}

export const EstimateWidgetLoader: FC<IEstimateWidgetLoaderProps> = ({
  isLoading,
}) => {
  if (!isLoading) return null

  return (
    <div
      className={
        styles.loadingForm +
        ' w-96 max-w-full rounded-lg bg-white pb-6 pl-6 pr-6 pt-8'
      }
    >
      <TitleSmall2.Serif.Book500>Loading...</TitleSmall2.Serif.Book500>
    </div>
  )
}
