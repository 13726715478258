import React from 'react'

import { COLORS, Caption } from 'ethos-design-system'

import { isEnterKeyPress } from '../../../../helpers/isEnterKeyPress'
import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../../Markdown'
import { CloseIcon } from '../CloseIcon'
import styles from './Original.module.scss'
import { LayoutProps } from './index'

const Original = ({
  dismissBanner,
  mobileContent,
  desktopContent,
  isGFI,
}: LayoutProps) => {
  return (
    <>
      <button
        className={styles.close}
        onClick={(e: React.MouseEvent): void => {
          dismissBanner(e)
        }}
        onKeyPress={(e: React.KeyboardEvent): void => {
          if (isEnterKeyPress(e)) {
            dismissBanner(e)
          }
        }}
      >
        {<CloseIcon color={isGFI ? 'black' : 'white'} />}
      </button>
      <div className={styles.inner}>
        <div className={styles.text}>
          {mobileContent && (
            <div className={styles.mobile}>
              <Caption.Regular400 color={COLORS.WHITE}>
                <Markdown
                  input={mobileContent}
                  allowedMarkdownTypes={
                    MARKDOWN_NODE_RESTRICTION_SETS.NEW_TAB_LINKS
                  }
                />
              </Caption.Regular400>
            </div>
          )}
          {desktopContent && (
            <div className={styles.desktop}>
              <Caption.Regular400 color={COLORS.WHITE}>
                <Markdown
                  input={desktopContent}
                  allowedMarkdownTypes={
                    MARKDOWN_NODE_RESTRICTION_SETS.NEW_TAB_LINKS
                  }
                />
              </Caption.Regular400>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Original
