import { WILLS_PAINTED_DOOR_V2_FLOW } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

const features = {
  control: 'https://app.tomorrow.me/documents/will/intro',
  curatedFluffFlow: 'wills',
  minimalFlow: 'wills-minimal',
}

export const useWillsFunnelFlow = () => {
  const {
    variation: paintedDoorV2Variation,
    isLoading: paintedDoorV2IsLoading,
  } = useDecisionForFlag({
    name: WILLS_PAINTED_DOOR_V2_FLOW.EXPERIMENT_KEY,
    fallbackVariation: WILLS_PAINTED_DOOR_V2_FLOW.VARIATIONS.CONTROL,
  })

  let flow
  let isTreatment = false

  switch (paintedDoorV2Variation) {
    case WILLS_PAINTED_DOOR_V2_FLOW.VARIATIONS.SHORT_FLOW:
      flow = 'wills-pdv2-short'
      isTreatment = true
      break
    case WILLS_PAINTED_DOOR_V2_FLOW.VARIATIONS.LONG_FLOW_TREATMENT_1:
      flow = 'wills-pdv2-l1'
      isTreatment = true
      break
    case WILLS_PAINTED_DOOR_V2_FLOW.VARIATIONS.LONG_FLOW_TREATMENT_2:
      flow = 'wills-pdv2-l2'
      isTreatment = true
      break
    default:
      flow = undefined
  }

  return {
    isLoading: paintedDoorV2IsLoading,
    isTreatment,
    isLeadForm: false,
    flow,
  }
}
