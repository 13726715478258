import React, { useCallback, useEffect, useState } from 'react'

import { Body2, Caption2, Spacer } from 'ethos-design-system'

import { Product } from '../../../../lib/@getethos/constants'
import { FINAL_EXPENSE_COVERAGE } from '../../../constants'
import { CoverageSlider } from '../../../shared/Estimate/Sliders'
import { getMinValidCoverage } from '../../../shared/Estimate/utils'
import { DATA_TID } from '../common/data-tid'
import { EstimateWidgetVariations } from './variations'

export interface FinalExpenseCoverageSliderProps {
  coverageLabel: string
  styles: any
  coverage: number
  maxCoverage: number
  variation?: string
  termLabel?: string
  handleCoverageChange: (event: any) => void
}

export const FinalExpenseCoverageSlider = (
  props: FinalExpenseCoverageSliderProps
) => {
  const [finalExpenseCoverage, setFinalExpenseCoverage] = useState(
    getMinValidCoverage(Product.FE)
  )

  const changeCoverage = useCallback(
    (newCoverage: number): void => {
      setFinalExpenseCoverage(newCoverage)
      props.handleCoverageChange(newCoverage)
    },
    [props]
  )

  useEffect(() => {
    const isValidMaxCoverage =
      finalExpenseCoverage >= FINAL_EXPENSE_COVERAGE.MIN &&
      finalExpenseCoverage <= props.maxCoverage

    if (!isValidMaxCoverage) {
      changeCoverage(FINAL_EXPENSE_COVERAGE.MIN)
    }
    if (
      props.coverage < FINAL_EXPENSE_COVERAGE.MIN ||
      props.coverage > props.maxCoverage
    ) {
      changeCoverage(FINAL_EXPENSE_COVERAGE.MIN)
    }
  }, [changeCoverage, finalExpenseCoverage, props])

  const isBoxedOrHeroVariation =
    props.variation === EstimateWidgetVariations.BOXED ||
    props.variation === EstimateWidgetVariations.HERO
  const isFloatingVariation =
    props.variation === EstimateWidgetVariations.FLOATING_LEAN

  return (
    <>
      <div className={props.styles.sliders}>
        {isBoxedOrHeroVariation && <div />}
        <CoverageSlider
          coverageLabel={props.coverageLabel}
          coverage={props.coverage}
          minCoverage={FINAL_EXPENSE_COVERAGE.MIN}
          maxCoverage={props.maxCoverage}
          coverageStep={FINAL_EXPENSE_COVERAGE.STEP}
          handleCoverageChange={changeCoverage}
          dataTid={DATA_TID.COVERAGE_INPUT}
          styles={props.styles}
          variation={props.variation}
        />
      </div>
      {isFloatingVariation && (
        <>
          <div className="text-gray-3">
            <Caption2.Regular400>{props.termLabel}</Caption2.Regular400>
          </div>
          <Spacer.H4 />
          <Body2.Regular400>Whole Life</Body2.Regular400>
          <Spacer.H16 />
        </>
      )}
    </>
  )
}
