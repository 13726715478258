import React from 'react'

import { Button, Caption, Spacer } from 'ethos-design-system'

import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../../Markdown'
import styles from './Manage.module.scss'
import { LayoutProps } from './index'

const Manage = ({
  dismissBanner,
  mobileContent,
  desktopContent,
  acceptButtonText,
  noManageCookies,
}: LayoutProps) => {
  return (
    <div className={styles.inner}>
      <div className={styles.text}>
        <img
          src={
            'https://res.cloudinary.com/getethos/image/upload/v1687389283/protection-icon_lfb8do.svg'
          }
          alt="Shield and checkmark icon"
          width={64}
          height={64}
        />
        <Spacer.H8 />
        {mobileContent && (
          <div className={styles.mobile}>
            <Caption.Regular400>
              <Markdown
                input={mobileContent}
                allowedMarkdownTypes={
                  MARKDOWN_NODE_RESTRICTION_SETS.NEW_TAB_LINKS
                }
              />
            </Caption.Regular400>
          </div>
        )}
        {desktopContent && (
          <div className={styles.desktop}>
            <Caption.Regular400>
              <Markdown
                input={desktopContent}
                allowedMarkdownTypes={
                  MARKDOWN_NODE_RESTRICTION_SETS.NEW_TAB_LINKS
                }
              />
            </Caption.Regular400>
          </div>
        )}
      </div>
      <Spacer.H24 />
      <Button.Medium.Black
        onClick={(e: React.MouseEvent): void => {
          dismissBanner(e)
        }}
      >
        {acceptButtonText || 'Accept'}
      </Button.Medium.Black>
      <Spacer.H16 />
      {!noManageCookies && (
        <a href="/privacy/" target="_blank">
          <Button.Medium.BlackOutline fullWidth>
            Manage cookies
          </Button.Medium.BlackOutline>
        </a>
      )}
    </div>
  )
}

export default Manage
