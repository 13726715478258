import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

type ModuleData = {
  pageLayout: Array<any>
  [key: string]: unknown
}

interface ModuleProviderProps extends PropsWithChildren {
  moduleData: ModuleData
}

type ModuleFilterCriteria = (module: any) => boolean

type ModuleControls = {
  setHideModules: (moduleCriteria: ModuleFilterCriteria) => void
  resetModules: () => void
}

type FooterControls = {
  isFooterHidden: boolean
  hideFooter: () => void
}

interface ModuleContextProps {
  moduleData: ModuleData
  moduleControls: ModuleControls
  footerControls: FooterControls
}

const ModuleContext = createContext<ModuleContextProps>({
  moduleData: { pageLayout: [] },
  moduleControls: {
    setHideModules: () => {},
    resetModules: () => {},
  },
  footerControls: {
    isFooterHidden: false,
    hideFooter: () => {},
  },
})

export const useModuleContext = () => {
  const context = useContext(ModuleContext)
  if (!context) {
    throw new Error('useModuleContext must be used within a ModuleProvider')
  }
  return context
}

export const ModuleProvider: FC<ModuleProviderProps> = ({
  children,
  moduleData,
}) => {
  const [moduleDataState, setModuleDataState] = useState(moduleData)
  const [footerHidden, setFooterHidden] = useState(false)

  const setHideModules = useCallback(
    (moduleCriteria: ModuleFilterCriteria) => {
      const newModuleData = moduleDataState.pageLayout.filter(moduleCriteria)
      setModuleDataState({ ...moduleDataState, pageLayout: newModuleData })
    },
    [moduleDataState]
  )

  const hideFooter = () => {
    setFooterHidden(true)
  }

  const resetModules = useCallback(() => {
    setModuleDataState(moduleData)
  }, [moduleData])

  const value = useMemo(
    () => ({
      moduleData: moduleDataState,
      footerControls: {
        isFooterHidden: footerHidden,
        hideFooter,
      },
      moduleControls: {
        setHideModules,
        resetModules,
      },
    }),
    [footerHidden, moduleDataState, resetModules, setHideModules]
  )

  return (
    <ModuleContext.Provider value={value}>{children}</ModuleContext.Provider>
  )
}
