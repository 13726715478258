import { WILLS_LP } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useWillsLPExperiment = () => {
  const { isLoading, variation } = useDecisionForFlag({
    name: WILLS_LP.EXPERIMENT_KEY,
    fallbackVariation: WILLS_LP.VARIATIONS.CONTROL,
  })

  return {
    isLoading,
    isTreatment1: variation === WILLS_LP.VARIATIONS.TREATMENT_1,
    isTreatment2: variation === WILLS_LP.VARIATIONS.TREATMENT_2,
    isOnTreatment:
      variation === WILLS_LP.VARIATIONS.TREATMENT_1 ||
      variation === WILLS_LP.VARIATIONS.TREATMENT_2,
    pageKeys: WILLS_LP.PAGE_VALUES,
  }
}
