import React from 'react'

import { Tooltip } from 'ethos-design-system'

import { InfoIcon } from '../../icons'

interface EstimateWidgetTooltipProps {
  details: string | JSX.Element
  label: string
  popperBoxStyles: string
  placement: 'top' | 'right' | 'left' | 'bottom' | 'auto'
  softCorners?: boolean
}

export const EstimateWidgetTooltip = (
  props: EstimateWidgetTooltipProps
): JSX.Element => (
  <Tooltip
    details={props.details}
    label={props.label}
    popperBoxStyles={props.popperBoxStyles}
    placement={props.placement}
    softCorners={props.softCorners}
  >
    <InfoIcon />
  </Tooltip>
)
