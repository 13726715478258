//@ts-nocheck

import { useCallback, useState } from 'react'

import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'

interface HookReturn {
  sendAnalyticsEvent: (fieldName: string, value: string) => void
}

const formTouchKey = 'formTouch'

export const useSendAnalytics = (analytics): HookReturn => {
  const [_, setAnalyticsState] = useState({})

  // Send analytics for EDS form
  // ------------------------------------------------------------
  const sendAnalyticsEvent = useCallback((fieldName, value) => {
    setAnalyticsState((prevState) => {
      const fieldNameToAnalyticsMap = {
        gender: analytics.genderCompleted,
        smoker: analytics.tobaccoCompleted,
        health: analytics.healthCompleted,
        birthDate: analytics.birthDateCompleted,
        zipCode: analytics.zipCodeCompleted,
        estimatedCredit: analytics.estimatedCreditCompleted,
      }

      if (!prevState[fieldName]) {
        // Update state and send analytics event
        if (!prevState[formTouchKey]) {
          estimateWidgetAnalytics.inputFormTouched({
            properties: { [formTouchKey]: true },
          })
        }

        fieldNameToAnalyticsMap[fieldName]({
          properties: { [fieldName]: value },
        })
        return { ...prevState, [fieldName]: true, [formTouchKey]: true }
      }

      return prevState
    })
  }, [])

  return {
    sendAnalyticsEvent,
  }
}
