//@ts-nocheck

import React from 'react'

import { COLORS, Caption2 } from 'ethos-design-system'

export interface Errors {
  [fieldName: string]: string
}

/**
 * translate Form.js "INVALID" error message to a helpful validation message
 * fieldErrors object comes from Form.js method: `getFieldErrors`
 */
export const translateErrors = (fieldErrors: Errors): Errors => {
  const translatedErrors = {}

  /**
   * The keys need to match a fieldName in the form
   * error message copy is specified in the Figma Experiment Mocks:
   * https://www.figma.com/file/XubkHF062yxs0AaIZFdFe1/Hero-Estimate-Widget?node-id=1807%3A1813
   */
  const ErrorMessages = {
    gender: 'Please select your gender',
    birthDate: 'Please enter your age',
    zipCode: 'Please enter your zip code',
    estimatedCredit: 'Please select your estimated credit score',
  }

  for (const [fieldName, errorMessage] of Object.entries(fieldErrors)) {
    translatedErrors[fieldName] =
      errorMessage === 'INVALID' ? ErrorMessages[fieldName] : errorMessage
  }
  return translatedErrors
}

const errorBorder =
  'border-solid border-0 border-b-2 border-salamander rounded-sm'

/**
 * Experimental Salamander Error wrapping field
 */
export const ExperimentalFieldWrapper = ({
  className = '',
  errorCopy,
  field,
  enabled = false,
}: {
  className?: string
  errorCopy?: string
  field: JSX.Element
  enabled?: boolean
}): JSX.Element =>
  !enabled ? (
    <div className={className}>{field}</div>
  ) : (
    <div className={className}>
      <div className={errorCopy ? errorBorder : ''}>{field}</div>
      {errorCopy && (
        <div className={'pt-2'}>
          <Caption2.Medium500 color={COLORS.BRAND_SALAMANDER}>
            {errorCopy}
          </Caption2.Medium500>
        </div>
      )}
    </div>
  )
