import React from 'react'

import { AirgapAPI } from '@transcend-io/airgap.js-types'
import { EVENT_NAMES } from 'lib/@getethos/analytics/constants'
import { trackSegmentEvent } from 'lib/@getethos/analytics/trackSegmentEvent'

declare global {
  interface Window {
    airgap?: AirgapAPI & {
      export: () => Object
    }
  }
}

export class PrivacyCenter {
  public static OptIn(UIEvent: React.MouseEvent | React.KeyboardEvent): void {
    if (window.airgap == null) {
      console.warn('Privacy Center is not initialized')
      trackSegmentEvent({
        event: EVENT_NAMES.PRIVACY_CENTER_AIRGAP_MISSING,
        properties: {},
      })
      return
    }
    const wasUserOptedIn = window.airgap.isOptedIn()
    const wasUserOptedOut = window.airgap.isOptedOut()
    const airgap_export_before = window.airgap.export()
    const airgap_consent_before = window.airgap.getConsent()
    const airgap_regimes_before = Array.from(window.airgap.getRegimes())

    window.airgap.optIn({ interaction: UIEvent.nativeEvent })

    const airgap_export_after = window.airgap.export()
    const airgap_consent_after = window.airgap.getConsent()
    const airgap_regimes_after = Array.from(window.airgap.getRegimes())

    trackSegmentEvent({
      event: EVENT_NAMES.PRIVACY_CENTER_USER_OPTED_IN,
      properties: {
        wasUserOptedIn,
        wasUserOptedOut,
        airgap_export_before,
        airgap_consent_before,
        airgap_regimes_before,
        airgap_export_after,
        airgap_consent_after,
        airgap_regimes_after,
      },
    })
  }
}
