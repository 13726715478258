import Head from 'next/head'
import { useRouter } from 'next/router'

import { MetaImage, MetaPage } from '@/components/DocumentHead'

import { useGlobalSettings } from '@/hooks/content/useGlobalSettings'

import { CmsConsentManager } from './global/CmsConsentManager'
import { StructuredData } from './global/StructuredData'

const PRECONNECT_SCRIPTS = [
  'https://logx.optimizely.com',
  'https://cdn.segment.com',
  'https://www.fullstory.com',
  'https://www.googleadservices.com',
  'https://connect.facebook.net',
  'https://googleads.g.doubleclick.net',
  'https://segment.ethoslife.com', // retrieves our `analytics.js` instance FROM segment
  'https://segment-api.ethoslife.com', // proxies requests TO segment api endpoints
  'https://riskid.ethoslife.com',
]

// //metaPageDescription
// :
// "Get an instant life insurance quote online today! Ethos makes life insurance easy by offering you a dependable quote in seconds."
// metaPageTitle
// :
// "Ethos Life | Affordable Online Life Insurance & Instant Quotes"
// pageMetaRobots
// :
// "crawl"
// pageSocialImage
// :
// "https://res.cloudinary.com/getethos/image/upload/v1565712064/01_NEW%20

interface MetaTagsProps {
  pageContent: {
    pageMetadata: {
      metaPageDescription: string
      metaPageTitle: string
      pageMetaRobots: string
      pageSocialImage: string
    }
    favicon?: string
    favicon16?: string
    favicon32?: string
    structuredData?: Array<Record<string, any>>
    pageLayout?: Array<Record<string, any>>
  }
  settings?: Record<string, any>
}

export const MetaTags = ({
  pageContent: { pageMetadata, structuredData, favicon, favicon16, favicon32 },
  settings,
  ...rest
}: MetaTagsProps) => {
  const { asPath } = useRouter()
  const organization = useGlobalSettings('organization.md', settings || {})
  if (!pageMetadata) return null
  const description = pageMetadata.metaPageDescription
  const title = pageMetadata.metaPageTitle || 'Ethos Life'
  // TODO: url to be deploy preview url on deploy preview
  // TODO: url to be https://stage.ethoslife.com on staging
  // mapped to appSiteUrl on gatsby
  const url = 'https://www.ethoslife.com'
  const canonicalUrl = (url + (asPath === '/' ? '' : asPath)).split('?')[0]
  let imageUrl = `${url}/img/ethos-ogimage.png`
  let twitterImageUrl = imageUrl
  const imageAlt = 'Ethos Life Insurance'

  const pageImage = pageMetadata.pageSocialImage
  // if pageImage is defined, change imageUrl and twitterImageUrl
  // we generate different sizes of pageImage for different social networks
  // For Twitter: 300x157 or maximum of 4096x4096 pixels to be used with summary_large_image
  // https://cloudinary.com/documentation/image_transformation_reference
  // https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/summary-card-with-large-image
  if (pageImage) {
    const publicIdBase = `https://res.cloudinary.com/getethos/image/upload/`
    const ogImageTransform = 'w_1200,h_1200,c_fit/'
    const twitterImageTransform = 'w_1024,h_512,c_fit/'

    const pageImageFilename = pageImage.replace(publicIdBase, '')
    imageUrl = `${publicIdBase}${ogImageTransform}${pageImageFilename}`
    twitterImageUrl = `${publicIdBase}${twitterImageTransform}${pageImageFilename}`
  }
  return (
    <Head>
      <meta name="author" content="Ethos Life" />
      <title>{pageMetadata.metaPageTitle}</title>
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <link rel="shortcut icon" href={favicon || '/favicon.ico'} />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={favicon16 || '/favicon-16x16.png'}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={favicon32 || '/favicon-32x32.png'}
      />
      {/* In the interest of protecting Ethos user's privacy. Airgap script must be injected as the very
          first script in header. This will allow airgap to act on things faster rather than later and protect any sensitive data leaks.
          Please reach out to Ethos MarTech<martech@getethos.com> for any questions
        */}
      <CmsConsentManager />
      {/* Next.js may move meta tags to a separate variable. */}
      {/* see: https://github.com/leerob/leerob.io/blob/main/app/layout.tsx#L14-L41 */}
      <StructuredData
        structuredData={structuredData}
        ogImage={imageUrl}
        {...organization}
        {...rest}
      />
      <MetaPage description={description} title={title} url={url} />
      <MetaImage
        imageUrl={imageUrl}
        imageAlt={imageAlt}
        twitterImageUrl={twitterImageUrl}
      />
      {PRECONNECT_SCRIPTS.map((script) => (
        <link
          key={script}
          rel="preconnect"
          href={script}
          crossOrigin="use-credentials"
        />
      ))}
      <link key={canonicalUrl} rel="canonical" href={canonicalUrl} />
    </Head>
  )
}
