import '@reach/slider/styles.css'

import React, { useMemo } from 'react'

import {
  AFFILIATES_NON_PREFILL_LP_URL,
  DAILY_PRICING_CPL_EXCLUSIONS,
  ESTIMATE_PRICE_RANGE_COPY,
} from '@/constants'
import { useSendAnalytics } from '@/shared/Estimate/useSendAnalytics'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { getAttributionCookie } from 'lib/@getethos/analytics/getAttributionCookie'

import {
  EstimateWidgetImageLayout,
  EstimateWidgetModuleData,
} from '../../EstimateWidget'
import { EstimateQuotesProvider } from '../../contexts/EstimateQuotesContext'
import { useEstimateWidgetContext } from '../../contexts/EstimateWidgetContext'
import { EstimateWidgetProgress } from '../../types/widget'
import { getFloatingFieldsRefactor } from '../config/getFloatingFieldsRefactor'
import { FloatingInfoFormRefactored } from './FloatingInforFormRefactor'
import { FloatingRangeFormRefactor } from './FloatingRangeForm/FloatingRangeFormRefactor'
import { EstimateWidgetLoader } from './components/EstimateWidgetLoader'
import { LeadFormWrapper } from './experiments/LeadForm/LeadFormWrapper'
import CPSales from './experiments/Sales/CPSales'

interface IFloatingFormManagerRefactorProps {
  moduleData: EstimateWidgetModuleData
  scrollNode: Element
}

export const FloatingFormManagerRefactor = ({
  moduleData,
  scrollNode,
}: IFloatingFormManagerRefactorProps) => {
  const {
    userDataControls: { userData },
    widgetProgressControls: { widgetProgress, setWidgetProgress },
  } = useEstimateWidgetContext()

  let container = ''

  const salamanderFormEnabled = moduleData.salamanderForm
  const cypressFormEnabled = moduleData.cypressForm

  const genderQuestion = moduleData.genderLabel || ''
  const birthdateQuestion = moduleData.birthLabel || ''
  const zipcodeQuestion = moduleData.zipLabel || ''
  const nicotineQuestion = moduleData.nicotineLabel || ''

  const fieldLabels = {
    birthdateQuestion,
    genderQuestion,
    nicotineQuestion,
    zipcodeQuestion,
  }

  const { sendAnalyticsEvent } = useSendAnalytics(estimateWidgetAnalytics)

  const estimatedCredit = {
    estimatedCreditFootnote: moduleData.estimatedCreditFootnote || '',
    estimatedCreditQuestion: moduleData.estimatedCreditLabel || '',
    displayEstimatedCredit: moduleData.displayEstimatedCredit || false,
    variation: moduleData.variation || '',
  }

  const floatingFields = useMemo(
    () =>
      getFloatingFieldsRefactor({
        userData,
        estimatedCredit,
        labels: fieldLabels,
        analytics: sendAnalyticsEvent,
        featureConfig: {
          hideNicotineQuestion: false,
        },
      }),
    [userData]
  )

  const boxShadow =
    moduleData?.imageLayout === EstimateWidgetImageLayout.MultiImage

  // Exclude CPL affiliates from daily premium pricing

  const currentPath =
    typeof window !== 'undefined' ? window.location.pathname : null
  const attributionCookie = getAttributionCookie() as any
  const pathToMatch = `/${AFFILIATES_NON_PREFILL_LP_URL}/`

  const cplAffiliate =
    currentPath === pathToMatch &&
    DAILY_PRICING_CPL_EXCLUSIONS.includes(attributionCookie['utmTerm'])

  const showDailyPremium = moduleData.dailyPremium && !cplAffiliate

  const estimateCopy = !showDailyPremium
    ? ESTIMATE_PRICE_RANGE_COPY.MONTHLY
    : moduleData.estimateRangeCopy || ESTIMATE_PRICE_RANGE_COPY.MONTHLY

  // End exclusion for CPL affiliates

  const isStraightToApp =
    moduleData.imageLayout === EstimateWidgetImageLayout.StraightToApp
  const ctaText = isStraightToApp
    ? 'See my estimate'
    : moduleData?.firstButton
    ? moduleData?.firstButton
    : 'Continue'

  const infoFormVariations = {
    asta: isStraightToApp,
    boxShadow,
    cypressForm: cypressFormEnabled,
    displayEstimatedCredit: moduleData?.displayEstimatedCredit,
    estimateCopy: moduleData.estimateInfoCopy || '',
    priceElasticityEnabled: moduleData?.priceElasticity,
    salamanderForm: salamanderFormEnabled,
    finalExpense:
      moduleData.imageLayout === EstimateWidgetImageLayout.FinalExpense,
  }

  return (
    <EstimateQuotesProvider moduleData={moduleData}>
      <div className={container}>
        <EstimateWidgetLoader
          isLoading={widgetProgress === EstimateWidgetProgress.LOADING}
        />
        {widgetProgress === EstimateWidgetProgress.INFO && (
          <FloatingInfoFormRefactored
            ctaText={ctaText}
            scrollNode={scrollNode}
            fields={floatingFields}
            variations={infoFormVariations}
            moduleData={moduleData}
          />
        )}
        {widgetProgress === EstimateWidgetProgress.PRICES && (
          <FloatingRangeFormRefactor
            asta={isStraightToApp}
            estimateCopy={estimateCopy}
            moduleData={moduleData}
          />
        )}
        {widgetProgress === EstimateWidgetProgress.LEAD_FORM && (
          <LeadFormWrapper />
        )}
        {widgetProgress === EstimateWidgetProgress.SALES && <CPSales />}
      </div>
    </EstimateQuotesProvider>
  )
}
