import {
  EW_DEFAULTS_AFFILIATES_V2,
  EW_PRICING_UPDATES,
} from 'lib/@getethos/experiments/feature_constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useEwUpdates = () => {
  const { isLoading: isLoadingV2, variation: variationV2 } = useDecisionForFlag(
    {
      name: EW_DEFAULTS_AFFILIATES_V2.name,
      fallbackVariation: EW_DEFAULTS_AFFILIATES_V2.variations.off,
    }
  )

  const { isLoading: isLoadingV1, variation: variationV1 } = useDecisionForFlag(
    {
      name: EW_PRICING_UPDATES.name,
      fallbackVariation: EW_PRICING_UPDATES.variations.off,
    }
  )

  return {
    isLoading: isLoadingV1 || isLoadingV2,
    isVariant1:
      variationV1 === EW_PRICING_UPDATES.variations.variant_1 ||
      variationV2 === EW_DEFAULTS_AFFILIATES_V2.variations.variation_1,
    isVariant2:
      variationV1 === EW_PRICING_UPDATES.variations.variant_2 ||
      variationV2 === EW_DEFAULTS_AFFILIATES_V2.variations.variation_2,
  }
}
