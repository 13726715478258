import React from 'react'

import { Button, Caption, Spacer } from 'ethos-design-system'

import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../../Markdown'
import styles from './Disclosures.module.scss'
import { LayoutProps } from './index'

const Disclosures = ({
  dismissBanner,
  content,
  acceptButtonText,
}: LayoutProps) => {
  return (
    <div className={styles.inner}>
      <div className={styles.text}>
        <img
          src={
            'https://res.cloudinary.com/getethos/image/upload/v1687389283/protection-icon_lfb8do.svg'
          }
          alt="Shield and checkmark icon"
          width={64}
          height={64}
        />
        <Spacer.H8 />
        {content && (
          <Caption.Regular400>
            <Markdown
              input={content}
              allowedMarkdownTypes={
                MARKDOWN_NODE_RESTRICTION_SETS.NEW_TAB_LINKS
              }
            />
            <Spacer.H8 />I further understand and agree that by clicking
            &quot;OK&quot; my electronic signature may be applied to the
            foregoing.
          </Caption.Regular400>
        )}
      </div>
      <Spacer.H24 />
      <Button.Medium.Black
        onClick={(e: React.MouseEvent): void => {
          dismissBanner(e)
        }}
      >
        {acceptButtonText || 'OK'}
      </Button.Medium.Black>
    </div>
  )
}

export default Disclosures
