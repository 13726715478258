import { PAID_LANDERS_UPDATE } from 'lib/@getethos/experiments/feature_constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const usePaidLandersUpdate = () => {
  const { isLoading, variation } = useDecisionForFlag({
    name: PAID_LANDERS_UPDATE.name,
    fallbackVariation: PAID_LANDERS_UPDATE.variations.off,
  })

  const isTreatment1 = variation === PAID_LANDERS_UPDATE.variations.treatment1
  const isTreatment2 = variation === PAID_LANDERS_UPDATE.variations.treatment2
  const isTreatment3 = variation === PAID_LANDERS_UPDATE.variations.treatment3

  const isTreatment = isTreatment1 || isTreatment2 || isTreatment3

  return {
    isLoading,
    isTreatment,
    isTreatment1,
    isTreatment2,
    isTreatment3,
  }
}
