import React from 'react'

export { default as Original } from './Original'
export { default as Accept } from './Accept'
export { default as Manage } from './Manage'
export { default as Disclosures } from './Disclosures'

export interface LayoutProps {
  dismissBanner: (event: React.MouseEvent | React.KeyboardEvent) => void
  mobileContent?: string
  desktopContent?: string
  content?: string
  noManageCookies?: boolean
  acceptButtonText?: string
  isGFI?: boolean
}

export enum LegalBannerLayouts {
  Original = 'original',
  Accept = 'ACCEPT',
  AcceptModal = 'ACCEPT_MODAL',
  Manage = 'MANAGE',
  ManageOk = 'MANAGE_OK',
  Disclosures = 'DISCLOSURES',
  DisclosuresOk = 'DISCLOSURES_OK',
  OK = 'OK',
}
